var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { "data-cy": "destination-cancel-btn" },
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: {
                            "data-cy": "destination-save-btn",
                            color: "green",
                            dark: "",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _vm.value.locationId
            ? _c(
                "v-alert",
                { attrs: { outlined: "", type: "info", text: "" } },
                [
                  _vm._v(
                    " The names and addresses of Locations included in the Common Destinations Table should be edited in the Locations Table. "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-form",
            {
              attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables },
            },
            [
              _c(
                "v-expansion-panels",
                { attrs: { accordion: "", mandatory: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    { attrs: { "data-cy": "destination-general-panel" } },
                    [
                      _c("v-expansion-panel-header", [_vm._v("General")]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      value: _vm.value.category,
                                      items: _vm.destinationCategories,
                                      label: "Category",
                                      required: "",
                                      "error-messages": _vm.handleErrors(
                                        _vm.$v.form.category
                                      ),
                                      readonly:
                                        _vm.value.readonly ||
                                        _vm.value.locationId > 0,
                                      "data-cy": "destination-category",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.category.$touch()
                                      },
                                    },
                                    nativeOn: {
                                      input: function ($event) {
                                        _vm.$v.form.category.$model =
                                          $event.srcElement.value
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.category.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.category,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.category.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "7" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.value.name,
                                      label: "Name",
                                      required: "",
                                      "error-messages": _vm.handleErrors(
                                        _vm.$v.form.name
                                      ),
                                      readonly:
                                        _vm.value.readonly ||
                                        _vm.value.locationId > 0,
                                      "data-cy": "destination-name",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.name.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.name,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.name.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      value: _vm.value.active,
                                      label: "Active",
                                      required: "",
                                      readonly:
                                        _vm.value.readonly ||
                                        _vm.value.locationId > 0,
                                      "data-cy": "destination-active-checkbox",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$v.form.active.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.active.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.active,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.form.active.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.value.email,
                                      label: "Email",
                                      required: "",
                                      readonly: _vm.value.readonly,
                                      "data-cy": "destination-email",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.email.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.email.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.email,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.email.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("phone-number", {
                                    attrs: {
                                      value: _vm.value.phone,
                                      label: "Phone",
                                      required: "",
                                      readonly: _vm.value.readonly,
                                      "data-cy": "destination-phone",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.phone.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.phone.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.phone,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.phone.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { attrs: { "data-cy": "destination-address-panel" } },
                    [
                      _c("v-expansion-panel-header", [_vm._v("Address")]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("destination-autocomplete", {
                            attrs: {
                              loading: _vm.loadingDestinations,
                              includeDestinations: false,
                              searchInput: _vm.searchInput,
                              hint: "Use this field to prefill the form below, or complete the form manually",
                              outlined: "",
                              "persistent-hint": "",
                              "data-cy": "destination-address-search",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.searchInput = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.searchInput = $event
                              },
                              destinationChanged: _vm.handleNewDestination,
                              internalLoading: (val) =>
                                (_vm.loadingDestinations = val),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ on, attrs, item }) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "orange",
                                              left: "",
                                            },
                                          },
                                          [_vm._v("mdi-map-marker-plus")]
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(item.text)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("v-divider", { staticClass: "my-6" }),
                          _c(
                            "v-row",
                            { staticClass: "pt-2" },
                            [
                              _c("address-form", {
                                ref: "addressForm",
                                attrs: {
                                  multiple: false,
                                  showLatLong: true,
                                  required: "",
                                },
                                model: {
                                  value: _vm.$v.form.address.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.form.address, "$model", $$v)
                                  },
                                  expression: "$v.form.address.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { attrs: { "data-cy": "destination-directions-panel" } },
                    [
                      _c("v-expansion-panel-header", [_vm._v("Directions")]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      label: "Directions",
                                      value: _vm.value.directions,
                                      readonly: _vm.value.readonly,
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.directions.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.directions.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.directions,
                                          "$model",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "$v.form.directions.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.value.id,
                          expression: "value.id",
                        },
                      ],
                      attrs: { "data-cy": "destination-certificate-panel" },
                    },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Certificate Liability Attachment"),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              !_vm.hasAttachments
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("upload-file", {
                                        ref: "uploadFile",
                                        attrs: {
                                          recordType: "destination",
                                          recordId: _vm.value.id,
                                          showSize: "",
                                          hint: !_vm.value.id
                                            ? "Save the destination before adding an attachment"
                                            : "",
                                          persistentHint: !_vm.value.id,
                                          readonly: !_vm.value.id,
                                        },
                                        on: { onUpload: _vm.onUpload },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.hasAttachments,
                                      expression: "hasAttachments",
                                    },
                                  ],
                                  attrs: { cols: "12", md: "12" },
                                },
                                [
                                  _c("attachments", {
                                    ref: "attachments",
                                    attrs: {
                                      recordType: "destination",
                                      recordId: _vm.value.id,
                                    },
                                    on: { onFiles: _vm.handleOnFiles },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("date-picker", {
                                    attrs: {
                                      label: "Certificate Expiration Date",
                                      value: _vm.value.liabilityExp,
                                      readonly: _vm.value.readonly,
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.$v.form.liabilityExp.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.$v.form.liabilityExp.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.liabilityExp,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression: "$v.form.liabilityExp.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }