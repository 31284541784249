var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: `Destination`,
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || (_vm.editedItem && !_vm.editedItem.id),
      hideCreate: _vm.prospective,
      deleteLabel: _vm.prospective ? "Remove" : "Delete",
      showSearch: "",
      pagination: "",
      prospective: _vm.prospective,
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
      moveToCommonDestinations: _vm.moveToCommonDestinations,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("destination-form", {
                    ref: "destinationForm",
                    attrs: { value: _vm.editedItem },
                    on: {
                      closeForm: _vm.closeForm,
                      submit: _vm.saveItem,
                      formChanges: _vm.formChanges,
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }